import * as React from "react"
import { Link } from "gatsby"
import Layout from "./layout"

const pageStyles = {
  color: "#232129",
  padding: "96px",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  maxWidth: 320,
}

const paragraphStyles = {
  marginBottom: 48,
}
const codeStyles = {
  color: "#8A6534",
  padding: 4,
  backgroundColor: "#FFF4DB",
  fontSize: "1.25rem",
  borderRadius: 4,
}

const NotFoundPage = () => {
  return (
    <Layout>
      <main style={pageStyles}>
        <div className="container">	<h1 className="hr-title dt-page-title">		<span>			LOST		</span>	</h1></div>
        <section id="primary" className="content-full-width">
          <div className="error-info">
            <div className="error-info-content">
              <h2> 404 </h2>
              <h3> OOPS! </h3>
              <h4 className="error_link"> Page Not Found </h4>
              <Link to="/" className="dt-sc-button small">Back to Home <i className="fa fa-home"></i></Link>.
            </div>
          </div>
        </section>
      </main>
    </Layout>
  )
}

export default NotFoundPage

export const Head = () => <title>Not found</title>
